// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-cv-index-tsx": () => import("./../../../src/pages/cv/index.tsx" /* webpackChunkName: "component---src-pages-cv-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blog_post/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */)
}


import React from "react"
import styles from './index.module.styl'

const Logo = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 -30 331.89 278.32" className={`${styles.logo} ${className ?? ''}`}>
      <path className={styles.left}
        d="M18,198.32V3.05a2.45,2.45,0,0,0-3-2.4A20,20,0,0,0,0,20V198.32a20,20,0,0,0,15,19.35,2.45,2.45,0,0,0,3-2.4Z" />
      <path className={styles.right}
            d="M251.89,18a2,2,0,0,1,2,2V198.32a2,2,0,0,1-2,2H170.72a8,8,0,0,1-7.08-4.27l-37.9-72a32,32,0,0,1,0-29.82l37.9-72A8,8,0,0,1,170.72,18h81.17m0-18H170.72a25.94,25.94,0,0,0-23,13.89l-37.9,72a50,50,0,0,0,0,46.59l37.9,72a25.94,25.94,0,0,0,23,13.89h81.17a20,20,0,0,0,20-20V20a20,20,0,0,0-20-20Z" />
      <path className={styles.top}
            d="M34.37,18h65.3A10.5,10.5,0,0,1,109,23.6l6.15,11.68a2.49,2.49,0,0,0,4.4,0l7.36-14a2.5,2.5,0,0,0,0-2.32l-2-3.77A28.51,28.51,0,0,0,99.67,0H34.37A1.37,1.37,0,0,0,33,1.37V16.63A1.37,1.37,0,0,0,34.37,18Z" />
      <path className={styles.bottom}
        d="M115.1,183,109,194.72a10.5,10.5,0,0,1-9.28,5.6H35.49A2.48,2.48,0,0,0,33,202.81v13a2.48,2.48,0,0,0,2.49,2.49H99.67a28.51,28.51,0,0,0,25.21-15.21l2-3.77a2.5,2.5,0,0,0,0-2.32l-7.36-14A2.49,2.49,0,0,0,115.1,183Z" />
    </svg>
  )
}

export default Logo

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/atikalaker/projects/programming/web/hmak-me/src/layout/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hossein Maktoobian","short_name":"hmak.me","start_url":"/","background_color":"#1c1c1e","theme_color":"#2c2c2e","display":"minimal-ui","icon":"src/assets/images/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cde9fd3864526b852db3c2e8e3bdc544"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
